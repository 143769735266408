// contentModelSegments.jsx

import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useDispatch } from "react-redux";
import {
  getContentsAsyncThunk,
  uploadContentAsyncThunk,
} from "../../../redux/pagesSlices/contentSlice";
import VideosList from "./VideosList";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FileUploader } from "react-drag-drop-files";
import { fileTypes, imageFileTypes } from "../../../constants";

function ContentModelBody({ closeModel }) {
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const [key, setKey] = useState("upload");
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [videoFile, setVideoFile] = useState([]);

  const handleThumbnailChange = (file) => {
    setThumbnailFile(file);
  };

  const handleVideoChange = (file) => {
    setVideoFile(file);
  };

  const getInternetSpeed = () => {
    console.log("Starting internet speed test");
    const image = new Image();
    const startTime = new Date().getTime();
    const downloadSize = 500000; // Approximate size in bytes

    return new Promise((resolve) => {
      image.onload = () => {
        const endTime = new Date().getTime();
        const duration = (endTime - startTime) / 1000;
        const bitsLoaded = downloadSize * 8;
        const speedBps = bitsLoaded / duration;
        const speedKbps = speedBps / 1024;
        const speedMbps = speedKbps / 1024;
        console.log("Internet speed (Mbps):", speedMbps);
        resolve(speedMbps);
      };

      image.onerror = () => {
        console.error("Failed to load speed test image.");
        resolve(0);
      };

      image.src = `https://dummyimage.com/500x500.png?text=SpeedTest&cache=${startTime}`;
    });
  };

  const fetchIPInfo = async () => {
    console.log("dadsdasdsadjas,fetchIPInfo");
    try {
      const response = await fetch(
        "https://ipinfo.io/json?token=468ee957bd3d01"
      );
      const data = await response.json();
      return {
        ipAddress: data.ip || "",
        location: data.city
          ? `${data.city}, ${data.region}, ${data.country}`
          : "",
        isp: data.org || "",
        host: data.hostname || "",
      };
    } catch (error) {
      return {};
    }
  };

  const getBrowserDetails = () => {
    const userAgent = navigator.userAgent;
    let browser = "Unknown";
    let os = "Unknown";
    // Simple browser detection
    if (userAgent.indexOf("Chrome") > -1) {
      browser = "Chrome";
    } else if (userAgent.indexOf("Firefox") > -1) {
      browser = "Firefox";
    } else if (userAgent.indexOf("Safari") > -1) {
      browser = "Safari";
    } else if (userAgent.indexOf("MSIE") > -1 || !!document.documentMode) {
      browser = "IE";
    }

    // Simple OS detection
    if (navigator.appVersion.indexOf("Win") !== -1) os = "Windows";
    if (navigator.appVersion.indexOf("Mac") !== -1) os = "MacOS";
    if (navigator.appVersion.indexOf("X11") !== -1) os = "UNIX";
    if (navigator.appVersion.indexOf("Linux") !== -1) os = "Linux";

    return {
      browser,
      os,
      device: navigator.platform,
      ispName: "", // To be filled from IP Info
    };
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log("Upload button clicked");

    const formData = new FormData();

    if (videoFile.length) {
      for (let i = 0; i < videoFile.length; i++) {
        formData.append("videoFile", videoFile[i]);
      }
    } else {
      console.log("No video file selected", videoFile);
      return;
    }

    formData.append("videoFile", videoFile);

    if (thumbnailFile) {
      formData.append("thumbnailFile", thumbnailFile);
    }

    try {
      let internetSpeed = await getInternetSpeed();
      console.log("🚀 ~ onSubmit ~ internetSpeed:", internetSpeed);
      const [ipInfo, browserDetails] = await Promise.all([
        fetchIPInfo(),
        getBrowserDetails(),
      ]);

      console.log("Fetched Data:", {
        ipInfo,
        browserDetails,
        internetSpeed,
      });

      formData.append("ipAddress", ipInfo.ipAddress || "");
      formData.append("location", ipInfo.location || "");
      formData.append("isp", ipInfo.isp || "");
      formData.append("host", ipInfo.host || "");
      formData.append("browser", browserDetails.browser || "");
      formData.append("os", browserDetails.os || "");
      formData.append("device", browserDetails.device || "");
      formData.append("internetSpeed", internetSpeed || 0);
      // Append other fields as necessary

      dispatch(
        uploadContentAsyncThunk({
          data: formData,

          callBack: () => {
            setTimeout(() => {
              setProgress(0);
              setVideoFile([]);
              setThumbnailFile(null);
            }, 1000);
          },
          options: {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setProgress(percentCompleted);
            },
            onDownloadProgress: (progressEvent) => {
              const progress =
                50 + (progressEvent.loaded / progressEvent.total) * 50;
              setProgress(progress);
            },
          },
        })
      );
      console.log("uploadContentAsyncThunk dispatched");
    } catch (error) {
      console.error("Error in onSubmit:", error);
    }
  };

  useEffect(() => {
    dispatch(
      getContentsAsyncThunk({
        sortBy: "sortName:desc",
        mimeType: "video",
      })
    );
  }, [dispatch]);

  return (
    <div>
      <div className="tabs-heading">
        <div>
          <div className="tabs-heading">
            <div>
              <h2>Media Gallery</h2>
            </div>
          </div>
        </div>
        {closeModel && (
          <div onClick={closeModel} className="modal-close-btn">
            <div className="model-icon">
              <i
                style={{ marginTop: "13px", fontWeight: 600, fontSize: 22 }}
                className="fa-thin fa-circle-xmark"
              ></i>
            </div>
          </div>
        )}
      </div>
      <div className="library-tabs">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="upload" title="Upload">
            <form onSubmit={onSubmit}>
              <div className="form_flex">
                <div className={"uploader-drag-drop card"}>
                  <h3>Select Videos to upload</h3>
                  <p>Or drag and drop video files</p>
                  <FileUploader
                    multiple={true}
                    handleChange={handleVideoChange}
                    name="video"
                    types={fileTypes}
                  />
                </div>
              </div>
              <p style={{ marginTop: "20px" }}>
                <ProgressBar now={progress} />
                <p>
                  {thumbnailFile
                    ? thumbnailFile.name
                    : "Select or drop image to upload!"}
                </p>
                {videoFile && videoFile.length ? (
                  <>
                    <span>File name:</span>
                    {Object.values(videoFile)?.map((item, i) => (
                      <span key={i}>
                        <br />
                        {`${i + 1} : ${item?.name}`}
                      </span>
                    ))}
                  </>
                ) : (
                  "Select or drop file to upload!"
                )}
              </p>
              <button
                style={{ padding: "5px 30px" }}
                className="btn btn-danger"
                type="submit"
              >
                Upload
              </button>
            </form>
          </Tab>

          <Tab eventKey="video" title="Video" className="video-tab">
            <VideosList _key={key} />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
export default React.memo(ContentModelBody);
