import React, { useEffect } from 'react'
import { getuploadDetailAsyncThunk } from '../redux/pagesSlices/linkSlice'
import { useDispatch, useSelector } from 'react-redux'
import './UploadLogs.css'
import moment from 'moment/moment'

const UploadLogs = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        console.log("uploadLogs")
        dispatch(getuploadDetailAsyncThunk({}))
    }, [dispatch])

    const uploadDetail = useSelector((s) => s?.links?.uploadDetail) ?? {};
    console.log("🚀 ~ uploadLogs ~ uploadDetail:", uploadDetail)
    const results = uploadDetail?.results || [];


    return (
        <div className="upload-logs-container">
            <table className="upload-logs-table">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>status</th>
                        <th>retryCount</th>
                        <th>ipAddress</th>
                        <th>location</th>
                        <th>isp</th>
                        <th>browser</th>
                        <th>os</th>
                        <th>device</th>
                        <th>internetSpeed</th>
                        <th>contentId</th>
                        <th>successTime</th>
                    </tr>
                </thead>
                <tbody>
                    {results.map(result => (
                        <tr key={result?.id}>
                            <td>{result?.id || 'N/A'}</td>
                            <td>{result?.status || 'N/A'}</td>
                            <td>{result?.retryCount || 'N/A'}</td>
                            <td>{result?.ipAddress || 'N/A'}</td>
                            <td>{result?.location || 'N/A'}</td>
                            <td>{result?.isp || 'N/A'}</td>
                            <td>{result?.browser || 'N/A'}</td>
                            <td>{result?.os || 'N/A'}</td>
                            <td>{result?.device || 'N/A'}</td>
                            <td>{(result?.internetSpeed) || 'N/A'}</td>
                            <td>{result?.contentId || 'N/A'}</td>
                            <td>{moment(result?.successTime).format('YYYY-MM-DD HH:mm:ss') || 'N/A'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default UploadLogs