// import logo from './logo.svg';
import "./App.scss";
import "./responsive.scss";
import React, { useEffect, useState } from "react";
import { Routes, Route, useParams, useLocation } from "react-router-dom";

import Home from "./pages/Home.jsx";
import YoutubeList from "./pages/YoutubeList.jsx";

import HomePlayer from "./screens/HomePlayer.jsx";
import YoutubePlayer from "./screens/YoutubePlayer.jsx";

import Upload from "./pages/Upload.jsx";

import Library from "./pages/Library.jsx";
import YoutubeLibrary from "./pages/YoutubeLibrary.jsx";

import CreateStamps from "./screens/CreateStamps.jsx";
import YoutubeCreateStamps from "./screens/YoutubeCreateStamps.jsx";

import Reporting from "./screens/Reporting.jsx";
import Perfomer from "./screens/Perfomer.jsx";
import PerfomerList from "./screens/PerfomerList.jsx";
import PerfomerListItem from "./screens/PerfomerListItem.jsx";



import Topbar from "../src/components/Topbar/Topbar";
import Responsivetopbar from "../src/components/Topbar/Responsivetopbar";
import Footer from "../src/components/Footer.jsx";

import Login from "./pages/auth/Login.js";
// import Signup from "./pages/auth/Signup";
import ProtectedRoute from "./components/ProtectedRoute.js";
import Unauthorized from "./components/Unauthorized";
import Page404 from "./screens/Page404.jsx";

import UpdateThumbnail from "./pages/UpdateThumbnail.jsx";
import Sidebar from "./components/Sidebar-lft/Sidebar.jsx";
import axios from "axios";

import UploadSegments from "./pages/uploadSegments"
import UploadLogs from "./pages/uploadLogs.js"

function App() {
  // let user = JSON?.parse(localStorage?.getItem("user"));
  const location = useLocation();
  console.log("🚀 ~ App ~ location:", location);
  console.log("🚀 ~env:", process.env.REACT_APP_NODE_ENV);

  const shouldShowSidebar =
    location.pathname == "/login" || location.pathname == "/signup";

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_contentBasePath}auth/current_user`,
          {
            withCredentials: true, // Ensure cookies are sent with the request
          }
        );
        console.log("🚀 ~ fetchUserData ~ response:", response);
        {
          response?.data?.data &&
            localStorage.setItem(
              "user",
              JSON.stringify(response?.data?.data?.user)
            );
        }
        {
          response?.data?.data?.tokens &&
            localStorage.setItem(
              "app-access-token",
              JSON.stringify(response?.data?.data?.tokens?.access?.token)
            );
        }
        {
          response?.data?.data?.tokens &&
            localStorage.setItem(
              "app-refresh-token",
              JSON.stringify(response?.data?.data?.tokens?.refresh?.token)
            );
        }
        setUserData(response.data.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <>
      <div className="App">
        <div className="flex-app">
          <div>
            <Topbar />
            <Responsivetopbar />
            <div className="App_flex_box">
              {!shouldShowSidebar && (
                <div className="Sidebar">
                  <Sidebar />
                </div>
              )}
              <div className="flex-grow-1">
                <Routes>
                  {/* auth routes */}
                  {/* <Route path="/signup" element={<Signup />} /> */}
                  <Route path="/login" element={<Login />} />

                  {/* admin */}

                  <Route
                    path="/admin/upload"
                    element={
                      <ProtectedRoute allowedRoles={["admin"]}>
                        <Upload />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/admin/upload/video"
                    element={
                      <ProtectedRoute allowedRoles={["admin"]}>
                        <UploadSegments />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/admin/upload/logs"
                    element={
                      <ProtectedRoute allowedRoles={["admin"]}>
                        <UploadLogs />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/admin/library"
                    element={
                      <ProtectedRoute allowedRoles={["admin"]}>
                        <Library />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/admin/createStamp/:id"
                    element={
                      <ProtectedRoute allowedRoles={["admin"]}>
                        <CreateStamps />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/admin/updateThumbnail/:id"
                    element={
                      <ProtectedRoute allowedRoles={["admin"]}>
                        <UpdateThumbnail />
                      </ProtectedRoute>
                    }
                  />

                  {/* user */}
                  <Route
                    path="/"
                    element={
                      <ProtectedRoute allowedRoles={["admin", "user"]}>
                        <Home />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/player/:id"
                    element={
                      <ProtectedRoute allowedRoles={["admin", "user"]}>
                        <HomePlayer />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/youtube"
                    element={
                      <ProtectedRoute allowedRoles={["admin", "user"]}>
                        <YoutubeList />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/youtube/:id"
                    element={
                      <ProtectedRoute allowedRoles={["admin", "user"]}>
                        <YoutubePlayer />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/admin/youtube-library"
                    element={
                      <ProtectedRoute allowedRoles={["admin"]}>
                        <YoutubeLibrary />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/admin/youtube-createStamp/:id"
                    element={
                      <ProtectedRoute allowedRoles={["admin"]}>
                        <YoutubeCreateStamps />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/admin/reports"
                    element={
                      <ProtectedRoute allowedRoles={["admin"]}>
                        <Reporting />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/admin/perfomer"
                    element={
                      <ProtectedRoute allowedRoles={["admin", "user"]}>
                        <Perfomer />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/admin/perfomer/list"
                    element={
                      <ProtectedRoute allowedRoles={["admin", "user"]}>
                        <PerfomerList />
                      </ProtectedRoute>
                    }
                  />


                  <Route
                    path="/performer/:id"
                    element={
                      <ProtectedRoute allowedRoles={["admin", "user"]}>
                        <PerfomerListItem />
                      </ProtectedRoute>
                    }
                  />

                  <Route path="/unauthorized" element={<Unauthorized />} />
                  <Route path="*" element={<Page404 />} />
                </Routes>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default App;
